<template>
  <div id="modified-ranking-scale">
    <h5>Modified Rankin Scale</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="infoText"
      class="mb-4"
      v-if="getDataSuccess && handleLogicStatement('modifiedRankingScaleInfo', 'visibility')"
    />

    <b-overlay :show="saveDataLoading">
      <fieldset :disabled="!canManagePatient">
        <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
          <!-- Date of assessment -->
          <datepicker
            v-if="
              handleLogicStatement('dateOfModifiedRankingScale', 'visibility')
            "
            v-model="content.modifiedRankingScale.dateOfModifiedRankingScale"
            label="Date of assessment"
            name="dateOfModifiedRankingScale"
            placeholder="Enter Date of assessment"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement('dateOfModifiedRankingScale', 'disable')"
            @changeEvent="updateData"
          />

          <!-- Inclusion criteria of drug -->
          <dropdown
            v-if="handleLogicStatement('inclusionCriteriaOfDrug', 'visibility')"
            v-model="content.modifiedRankingScale.inclusionCriteriaOfDrug"
            label="Inclusion criteria of drug"
            name="inclusionCriteriaOfDrug"
            :options="options.drugList"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <div v-if="content.modifiedRankingScale.inclusionCriteriaOfDrug === 'alglucosidaseAlfa'">
            <label>Treatment in Late Onset Pompe patient:</label>
            <ol>
              <li>
                Presence of muscle weakness and/or respiratory weaknesses which
                impair the patient's daily life or his working activities. the
                limitation on the Modified Rankin - Scale is at least number 2
              </li>
              <li>
                Stage of disease where an improvement under therapy can still be
                expected. Particularly:
                <ol type="a" class="mt-1">
                  <li>
                    the patient does not have invasive ventilation via tracheotomy
                  </li>
                  <li>
                    the patient is not constantly bed bound ans completely
                    depending on care by a third person. The limitation on the
                    Modified Rankin - Scale is not more than number 4
                  </li>
                </ol>
              </li>
            </ol>
          </div>

          <!-- Modified Rankin Scale -->
          <dropdown
            v-if="handleLogicStatement('modifiedRankingScale', 'visibility')"
            v-model="content.modifiedRankingScale.modifiedRankingScale"
            label="Modified Rankin Scale"
            name="modifiedRankingScale"
            :options="options.selectionList"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
            @updateFieldValue="updateFieldValue"
          />

          <hr />

          <div
            v-if="canManagePatient"
            class="text-center buttons mt-3"
          >
            <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
              Save & Continue
            </button>
          </div>
        </form>
      </fieldset>
    </b-overlay>
  </div>
</template>

<script>
import ModifiedRankingScaleMixin from './ModifiedRankingScale.mixin';
import { $getInfoText } from '@/helper/globalFunction';

export default {
  name: 'ModifiedRankingScaleForm',
  props: {
    canManagePatient: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    ModifiedRankingScaleMixin
  ],
  data: () => {
    return {
      infoText: "",
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    handleGetDataSuccess(response) {
      // Set new navs, if there's any
      if (response.data.nav.length > 0) {
        this.assignTabs(response.data.nav);
        // Reconstruct nav, based on each of the status
        this.reconstructTab();
      }

      this.checkFormStatus(
        null,
        "modifiedRankingScale",
        "GET",
        response.data.nav
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "modifiedRankingScaleInfo",
        this.options.info
      );

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
  },
};
</script>

<style></style>
