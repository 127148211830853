<template>
  <div id="sixMinuteWalkTest">
    <h5>Six Minute Walk Test</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="infoText"
      v-if="
        getDataSuccess &&
        handleLogicStatement('sixMinuteWalkTestInfo', 'visibility')
      "
      class="mb-4"
    />

    <b-overlay :show="saveDataLoading">
      <fieldset :disabled="!canManagePatient">
        <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
          <!-- Date of 6MWT -->
          <datepicker
            v-if="handleLogicStatement('dateOf6MWT', 'visibility')"
            v-model="content.sixMinuteWalkTest.dateOf6MWT"
            label="Date of 6MWT"
            name="dateOf6MWT"
            placeholder="Enter Date of 6MWT"
            :content="content"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement('dateOf6MWT', 'disable')"
            @changeEvent="updateData"
          />

          <!-- Duration of test -->
          <dropdown
            v-if="handleLogicStatement('durationTest', 'visibility')"
            v-model="content.sixMinuteWalkTest.durationTest"
            label="Duration of Test"
            name="durationTest"
            :options="options.durationList"
            :content="content"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- Start Time of 6MWT -->
          <label> Start Time of 6MWT </label>
          <div class="row">
            <div class="col">
              <text-field
                v-model="content.sixMinuteWalkTest.startTime6MWTHour"
                label="Hour"
                name="startTime6MWTHour"
                placeholder="Hour"
                type="double"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
            <div class="col">
              <text-field
                v-model="content.sixMinuteWalkTest.startTime6MWTMinute"
                label="Minutes"
                name="startTime6MWTMinute"
                placeholder="Minutes"
                type="double"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
          </div>

          <!-- End Time of 6MWT -->
          <label> End Time of 6MWT </label>
          <div class="row">
            <div class="col">
              <text-field
                v-model="content.sixMinuteWalkTest.endTime6MWTHour"
                label="Hour"
                name="endTime6MWTHour"
                placeholder="Hour"
                type="double"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
            <div class="col">
              <text-field
                v-model="content.sixMinuteWalkTest.endTime6MWTMinute"
                label="Minutes"
                name="endTime6MWTMinute"
                placeholder="Minutes"
                type="double"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
          </div>

          <!-- Custom Duration of Test -->
          <div class="row">
            <div
              class="col"
              v-if="
                handleLogicStatement('durationTestOtherMinutes', 'visibility')
              "
            >
              <!-- Minutes -->
              <text-field
                v-model="content.sixMinuteWalkTest.durationTestOtherMinutes"
                label="Other Minutes"
                name="durationTestOtherMinutes"
                placeholder="Minutes"
                type="double"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
            <div
              class="col"
              v-if="
                handleLogicStatement('durationTestOtherSeconds', 'visibility')
              "
            >
              <!-- Seconds -->
              <text-field
                v-model="content.sixMinuteWalkTest.durationTestOtherSeconds"
                label="Other Seconds"
                name="durationTestOtherSeconds"
                placeholder="Seconds"
                type="double"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
          </div>

          <!-- Stopped or paused before 6 minutes -->
          <dropdown
            v-if="handleLogicStatement('stoppedBefore6Minutes', 'visibility')"
            v-model="content.sixMinuteWalkTest.stoppedBefore6Minutes"
            label="Stopped or paused before 6 minutes"
            name="stoppedBefore6Minutes"
            :options="options.defaultOpt"
            :content="content"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- Reason to stop of pause -->
          <text-area
            v-if="handleLogicStatement('reasonStop', 'visibility')"
            v-model="content.sixMinuteWalkTest.reasonStop"
            name="reasonStop"
            label="Reason to stop or pause"
            placeholder="Enter Reason to stop or pause"
            :content="content"
            :logic="logic"
            :errors="errors"
            @blurEvent="updateData"
          />

          <!-- Assistive Walking Devices and Orthoses -->
          <dropdown
            v-if="handleLogicStatement('assistiveWalkingDevices', 'visibility')"
            v-model="content.sixMinuteWalkTest.assistiveWalkingDevices"
            label="Assistive Walking Devices and Orthoses"
            name="assistiveWalkingDevices"
            :options="options.assistiveWalkingDevicesList"
            :content="content"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- Specify Other Devices -->
          <text-area
            v-if="handleLogicStatement('specifyOtherDevices', 'visibility')"
            v-model="content.sixMinuteWalkTest.specifyOtherDevices"
            name="specifyOtherDevices"
            label="Specify Other Devices"
            placeholder="Enter Specify Other Devices"
            :logic="logic"
            :content="content"
            :errors="errors"
            @blurEvent="updateData"
          />

          <!-- Was the patient on respiratory support
            (e.g., ventilation, CPAP) during the test: -->
          <dropdown
            v-if="handleLogicStatement('respiratorySupportGiven', 'visibility')"
            v-model="content.sixMinuteWalkTest.respiratorySupportGiven"
            label="Was the patient on respiratory support (e.g., ventilation, CPAP)
              during the test:"
            name="respiratorySupportGiven"
            :options="options.defaultOpt"
            :content="content"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- Type of respiratory support -->
          <dropdown
            v-if="handleLogicStatement('typeRespiratorySupport', 'visibility')"
            v-model="content.sixMinuteWalkTest.typeRespiratorySupport"
            label="Type of respiratory support"
            name="typeRespiratorySupport"
            :content="content"
            :options="options.listRespiratorySupport"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- Other Respiratory Support -->
          <text-field
            v-if="handleLogicStatement('otherRespiratorySupport', 'visibility')"
            v-model="content.sixMinuteWalkTest.otherRespiratorySupport"
            label="Other Respiratory Support"
            name="otherRespiratorySupport"
            placeholder="Other Respiratory Support"
            type="text"
            :content="content"
            :logic="logic"
            :errors="errors"
            @blurEvent="updateData"
          />

          <!-- Pre-test blood pressure (systolic/diastolic) (mm Hg) -->
          <div class="row">
            <div class="col-5">
              <text-field
                :key="updateComponent"
                v-model="content.sixMinuteWalkTest.pretestBloodPressureSystolic"
                label="Pre-test blood pressure (systolic)"
                name="pretestBloodPressureSystolic"
                type="double"
                placeholder="Pre-test blood pressure (systolic)"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
            <div class="col-5">
              <text-field
                :key="updateComponent"
                v-model="content.sixMinuteWalkTest.pretestBloodPressureDiastolic"
                label="Pre-test blood pressure (diastolic)"
                name="pretestBloodPressureDiastolic"
                type="double"
                placeholder="Pre-test blood pressure (diastolic)"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
            <div class="col-2">
              <label> Unknown </label>
              <b-form-checkbox
                class="mt-1"
                name="check-button"
                switch
                v-model="content.sixMinuteWalkTest.pretestBloodPressureUnknown"
                :content="content"
                :disabled="handleLogicStatement('pretestBloodPressureUnknown', 'disable')"
                @input="eventOnInput('pretestBloodPressureUnknown', ['pretestBloodPressureDiastolic', 'pretestBloodPressureSystolic'])"
              >
              </b-form-checkbox>
            </div>
          </div>

          <!-- Heart Rate Tests -->
          <div class="row">
            <div class="col-5">
              <text-field
                :key="updateComponent"
                v-model="content.sixMinuteWalkTest.pretestHeartRate"
                label="Pre-Test Heart Rate (BPM)"
                name="pretestHeartRate"
                type="double"
                placeholder="Pre-Test Heart Rate (BPM)"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
            <div class="col-5">
              <text-field
                :key="updateComponent"
                v-model="content.sixMinuteWalkTest.posttestHeartRate"
                label="Post-Test Heart Rate (BPM)"
                name="posttestHeartRate"
                type="double"
                placeholder="Post-Test Heart Rate (BPM)"
                :content="content"
                :logic="logic"
                :errors="errors"
                @blurEvent="updateData"
              />
            </div>
            <div class="col-1">
              <label> Unknown </label>
              <b-form-checkbox
                switch
                class="mt-1"
                name="check-button"
                v-model="content.sixMinuteWalkTest.postTestHeartRateNA"
                @input="eventOnInput('postTestHeartRateNA', ['posttestHeartRate', 'pretestHeartRate'])"
              >
              </b-form-checkbox>
            </div>
          </div>

          <!-- Dyspnoea Test -->
          <div class="row">
            <div class="col-6">
              <dropdown
                v-if="handleLogicStatement('pretestDyspnoe', 'visibility')"
                v-model="content.sixMinuteWalkTest.pretestDyspnoe"
                label="Pre-Test Dyspnoea (Borg Scale)"
                name="pretestDyspnoe"
                :options="options.borgScale"
                :content="content"
                :logic="logic"
                :errors="errors"
                @changeEvent="updateData"
              />
            </div>
            <div class="col-6">
              <dropdown
                v-if="handleLogicStatement('posttestDyspnoe', 'visibility')"
                v-model="content.sixMinuteWalkTest.posttestDyspnoe"
                label="Post-Test Dyspnoea (Borg Scale)"
                name="posttestDyspnoe"
                :options="options.borgScale"
                :content="content"
                :logic="logic"
                :errors="errors"
                @changeEvent="updateData"
              />
            </div>
          </div>

          <!-- Fatigue Test -->
          <div class="row">
            <div class="col">
              <dropdown
                v-if="handleLogicStatement('pretestFatigue', 'visibility')"
                v-model="content.sixMinuteWalkTest.pretestFatigue"
                label="Pre-Test Fatigue (Borg Scale)"
                name="pretestFatigue"
                :options="options.borgScale"
                :content="content"
                :logic="logic"
                :errors="errors"
                @changeEvent="updateData"
              />
            </div>
            <div class="col">
              <dropdown
                v-if="handleLogicStatement('posttestFatigue', 'visibility')"
                v-model="content.sixMinuteWalkTest.posttestFatigue"
                label="Post-Test Fatigue (Borg Scale)"
                name="posttestFatigue"
                :options="options.borgScale"
                :content="content"
                :logic="logic"
                :errors="errors"
                @changeEvent="updateData"
              />
            </div>
          </div>

          <!-- Other symptoms at the end of the exercise -->
          <autocomplete 
            v-if="handleLogicStatement('otherSymptomsEndExercises', 'visibility')"
            v-model="content.sixMinuteWalkTest.otherSymptomsEndExercises"
            label="Other symptoms at the end of the exercise"
            placeholder="Other symptoms at the end of the exercise"
            name="otherSymptomsEndExercises"
            :options="options.listOtherSymptoms"
            :content="content"
            :logic="logic"
            :isDisabled="!canManagePatient"
            :errors="errors"
            @blurEvent="updateData"
          />

          <!-- Specify Other Symptoms -->
          <text-field
            v-if="handleLogicStatement('specifyOtherSymptoms', 'visibility')"
            v-model="content.sixMinuteWalkTest.specifyOtherSymptoms"
            name="specifyOtherSymptoms"
            type="text"
            label="Specify Other Symptoms"
            placeholder="Enter Specify Other Symptoms"
            :content="content"
            :logic="logic"
            :errors="errors"
            @blurEvent="updateData"
          />

          <!-- Total Distance Walked (m) -->
          <b-form-group>
            <label> Total Distance Walked (m) </label>
            <b-form-checkbox
              v-model="content.sixMinuteWalkTest.totalDistanceWalkedUnknown"
              switch
              name="check-button"
              class="mb-1"
              :disabled="handleLogicStatement('totalDistanceWalkedUnknown', 'disable')"
              @input="eventOnInput('totalDistanceWalkedUnknown', ['totalDistanceWalked'])"
            >
              <label> Unknown </label>
            </b-form-checkbox>
          </b-form-group>
          <text-field
            :key="updateComponent"
            v-model="content.sixMinuteWalkTest.totalDistanceWalked"
            type="double"
            name="totalDistanceWalked"
            placeholder="Total Distance Walked (m)"
            :logic="logic"
            :content="content"
            :errors="errors"
            @blurEvent="updateData"
          />

          <hr />

          <div
            v-if="canManagePatient"
            class="text-center buttons mt-3"
          >
            <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
              Save & Continue
            </button>
          </div>
        </form>
      </fieldset>
    </b-overlay>
  </div>
</template>

<script>
import SixMinuteWalkTestMixin from './SixMinuteWalkTest.mixin';
import { $getInfoText } from '@/helper/globalFunction';

export default {
  props: {
    canManagePatient: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    SixMinuteWalkTestMixin
  ],
  data: () => {
    return {
      updateComponent: 1,
      infoText: "",
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(null, "sixMinuteWalkTest", "GET", response.data.nav);

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.options.listOtherSymptoms = this.options.listOtherSymptoms.filter(el => el.value !== null);

      this.infoText = this.$getInfoText(
        "sixMinuteWalkTestInfo",
        this.options.info
      );

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
    eventOnInput(currentField, affectedField) {
      this.updateRelatedField(currentField, affectedField);
      this.updateData(currentField);
      this.updateComponent++;
    }
  },
};
</script>

<style></style>
