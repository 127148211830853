<template>
  <div id="pulmonaryFunctionTest">
    <h5>Pulmonary Function Test</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="infoText"
      class="mb-4"
      v-if="
        getDataSuccess &&
        handleLogicStatement('pulmonaryFunctionTestInfo', 'visibility')
      "
    />

    <b-overlay :show="saveDataLoading">
      <fieldset :disabled="!canManagePatient">
        <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
          <div class="row">
            <div class="col-5">
              <!-- Date of Assessment -->
              <datepicker
                v-if="handleLogicStatement('dateOfPulmonaryFunctionTest', 'visibility')"
                v-model="content.pulmonaryFunctionTest.dateOfPulmonaryFunctionTest"
                label="Date of Assessment"
                name="dateOfPulmonaryFunctionTest"
                placeholder="Enter Date of Assessment"
                :content="content"
                :logic="logic"
                :errors="errors"
                :isDisable="handleLogicStatement('dateOfPulmonaryFunctionTest', 'disable')"
                @changeEvent="updateData"
              />

              <!-- FVC (upright) (l) -->
              <div class="row">
                <div class="col-7">
                  <decimal-input
                    v-if="handleLogicStatement('fVCUprightL', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.fVCUprightL"
                    label="FVC (upright) (l)"
                    name="fVCUprightL"
                    placeholder="FVC (upright) (l)"
                    :content="content"
                    :logic="logic"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-5">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="fVCUprightLNotPerformed"
                    switch
                    v-model="
                      content.pulmonaryFunctionTest.fVCUprightLNotPerformed
                    "
                    :disabled="handleLogicStatement('fVCUprightLNotPerformed', 'disable')"
                    @input="eventOnInput('fVCUprightLNotPerformed', ['fVCUprightL'])"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.fVCUprightLNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- FVC (upright) % predicted normal (%) -->
              <div class="row">
                <div class="col-7">
                  <decimal-input
                    v-if="handleLogicStatement('fVCUprightPredictedNormal', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.fVCUprightPredictedNormal"
                    label="FVC (upright) % predicted normal (%)"
                    name="fVCUprightPredictedNormal"
                    placeholder="FVC (upright) % predicted normal (%)"
                    :content="content"
                    :logic="logic"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-5">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="fVCUprightPredictedNormalNotPerformed"
                    switch
                    v-model="
                      content.pulmonaryFunctionTest
                        .fVCUprightPredictedNormalNotPerformed
                    "
                    :disabled="
                      handleLogicStatement('fVCUprightPredictedNormalNotPerformed', 'disable')
                    "
                    @input="eventOnInput(
                      'fVCUprightPredictedNormalNotPerformed', 
                      ['fVCUprightPredictedNormal']
                    )"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest
                            .fVCUprightPredictedNormalNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- FEV1 (upright) (L) -->
              <div class="row">
                <div class="col-7">
                  <decimal-input
                    v-if="handleLogicStatement('fEV1UprightL', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.fEV1UprightL"
                    label="FEV1 (upright) (L)"
                    name="fEV1UprightL"
                    placeholder="FEV1 (upright) (L)"
                    :content="content"
                    :logic="logic"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-5">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="fEV1UprightLNotPerformed"
                    switch
                    v-model="
                      content.pulmonaryFunctionTest.fEV1UprightLNotPerformed
                    "
                    :disabled="handleLogicStatement('fEV1UprightLNotPerformed', 'disable')"
                    @input="eventOnInput('fEV1UprightLNotPerformed', ['fEV1UprightL'])" 
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.fEV1UprightLNotPerfomed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- FEV1 (upright) % predicted normal (%) -->
              <div class="row">
                <div class="col-7">
                  <decimal-input
                    v-if="handleLogicStatement('fEV1UprightPredictedNormal', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.fEV1UprightPredictedNormal"
                    label="FEV1 (upright) % predicted normal (%)"
                    name="fEV1UprightPredictedNormal"
                    placeholder="FEV1 (upright) % predicted normal (%)"
                    :content="content"
                    :logic="logic"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-5">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="fEV1UprightPredictedNormalNotPerformed"
                    switch
                    v-model="
                      content.pulmonaryFunctionTest
                        .fEV1UprightPredictedNormalNotPerformed
                    "
                    :disabled="
                      handleLogicStatement('fEV1UprightPredictedNormalNotPerformed', 'disable')
                    "
                    @input="eventOnInput(
                      'fEV1UprightPredictedNormalNotPerformed', 
                      ['fEV1UprightPredictedNormal']
                    )"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest
                            .fEV1UprightPredictedNormalNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- FVC (supine) (l) -->
              <div class="row">
                <div class="col-7">
                  <decimal-input
                    v-if="handleLogicStatement('fVCSupineL', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.fVCSupineL"
                    label="FVC (supine) (l)"
                    name="fVCSupineL"
                    placeholder="FVC (supine) (l)"
                    :content="content"
                    :logic="logic"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-5">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="fVCSupineLNotPerformed"
                    switch
                    v-model="content.pulmonaryFunctionTest.fVCSupineLNotPerformed"
                    :disabled="handleLogicStatement('fVCSupineLNotPerformed', 'disable')"
                    @input="eventOnInput('fVCSupineLNotPerformed', ['fVCSupineL'])"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.fVCSupineLNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- FVC (supine) % predicted normal (%) -->
              <div class="row">
                <div class="col-7">
                  <decimal-input
                    v-if="handleLogicStatement('fVCSupinePredictedNormal', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.fVCSupinePredictedNormal"
                    label="FVC (supine) % predicted normal (%)"
                    name="fVCSupinePredictedNormal"
                    placeholder="FVC (supine) % predicted normal (%)"
                    :content="content"
                    :logic="logic"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-5">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="fVCSupinePredictedNormalNotPerformed"
                    switch
                    v-model="
                      content.pulmonaryFunctionTest
                        .fVCSupinePredictedNormalNotPerformed
                    "
                    :disabled="handleLogicStatement('fVCSupinePredictedNormalNotPerformed', 'disable')"
                    @input="eventOnInput(
                      'fVCSupinePredictedNormalNotPerformed',
                      ['fVCSupinePredictedNormal']
                    )"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.fVCSupinePredictedNormalNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- FEV1 (supine) (L) -->
              <div class="row">
                <div class="col-7">
                  <decimal-input
                    v-if="handleLogicStatement('fEV1SupineL', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.fEV1SupineL"
                    label="FEV1 (supine) (L)"
                    name="fEV1SupineL"
                    placeholder="FEV1 (supine) (L)"
                    :logic="logic"
                    :content="content"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-5">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="fEV1SupineLNotPerformed"
                    switch
                    v-model="
                      content.pulmonaryFunctionTest.fEV1SupineLNotPerformed
                    "
                    :disabled="handleLogicStatement('fEV1SupineLNotPerformed', 'disable')"
                    @input="eventOnInput('fEV1SupineLNotPerformed', ['fEV1SupineL'])"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.fEV1SupineLNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- FEV1 (supine) % predicted normal (%) -->
              <div class="row">
                <div class="col-7">
                  <decimal-input
                    v-if="handleLogicStatement('fEV1SupinePredictedNormal', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.fEV1SupinePredictedNormal"
                    label="FEV1 (supine) % predicted normal (%)"
                    name="fEV1SupinePredictedNormal"
                    placeholder="FEV1 (supine) % predicted normal (%)"
                    :content="content"
                    :logic="logic"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-5">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="fEV1SupinePredictedNormalNotPerformed"
                    switch
                    v-model="
                      content.pulmonaryFunctionTest
                        .fEV1SupinePredictedNormalNotPerformed
                    "
                    :disabled="
                      handleLogicStatement('fEV1SupinePredictedNormalNotPerformed', 'disable')
                    "
                    @input="eventOnInput(
                      'fEV1SupinePredictedNormalNotPerformed', 
                      ['fEV1SupinePredictedNormal']
                    )"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.fEV1SupinePredictedNormalNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>
            </div>
            <div class="col-7">
              <!-- MIP (PImax) -->
              <div class="row">
                <div class="col-4">
                  <decimal-input
                    v-if="handleLogicStatement('mIPPImax', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.mIPPImax"
                    label="MIP (PImax)"
                    name="mIPPImax"
                    placeholder="MIP (PImax)"
                    :content="content"
                    :logic="logic"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-4">
                  <dropdown
                    v-if="
                      handleLogicStatement('mIPPImaxUnit', 'visibility')
                    "
                    v-model="content.pulmonaryFunctionTest.mIPPImaxUnit"
                    label="MIP (PImax) Unit"
                    name="mIPPImaxUnit"
                    :options="options.unitList"
                    :logic="logic"
                    :content="content"
                    :errors="errors"
                    @changeEvent="updateData"
                  />
                </div>
                <div class="col-4">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    v-if="handleLogicStatement('mIPPImaxNotPerformed', 'visibility')"
                    class="mt-1"
                    name="check-button"
                    id="mIPPImaxNotPerformed"
                    switch
                    v-model="content.pulmonaryFunctionTest.mIPPImaxNotPerformed"
                    :disabled="handleLogicStatement('mIPPImaxNotPerformed', 'disable')"
                    @input="eventOnInput('mIPPImaxNotPerformed', ['mIPPImaxUnit', 'mIPPImax'])"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.mIPPImaxNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- MEP (PImax) -->
              <div class="row">
                <div class="col-4">
                  <decimal-input
                    v-if="handleLogicStatement('mEPPImax', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.mEPPImax"
                    label="MEP (PImax)"
                    name="mEPPImax"
                    placeholder="MEP (PImax)"
                    :logic="logic"
                    :content="content"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-4">
                  <dropdown
                    v-if="
                      handleLogicStatement('mEPPImaxUnit', 'visibility')
                    "
                    v-model="content.pulmonaryFunctionTest.mEPPImaxUnit"
                    label="MEP (PImax) Unit"
                    name="mEPPImaxUnit"
                    :options="options.unitList"
                    :logic="logic"
                    :content="content"
                    :errors="errors"
                    @changeEvent="updateData"
                  />
                </div>
                <div class="col-4">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    v-if="handleLogicStatement('mEPPImaxUnit', 'visibility')"
                    class="mt-1"
                    name="check-button"
                    id="mEPPImaxNotPerformed"
                    switch
                    v-model="content.pulmonaryFunctionTest.mEPPImaxNotPerformed"
                    :disabled="handleLogicStatement('mEPPImaxNotPerformed', 'disable')"
                    @input="eventOnInput('mEPPImaxNotPerformed', ['mEPPImaxUnit', 'mEPPImax'])"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.mEPPImaxNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- SNIP (upright) (cm H2O) -->
              <div class="row">
                <div class="col-4">
                  <decimal-input
                    v-if="handleLogicStatement('sNIPUpright', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.sNIPUpright"
                    label="SNIP (upright) (cm H2O)"
                    name="sNIPUpright"
                    placeholder="SNIP (upright) (cm H2O)"
                    :logic="logic"
                    :content="content"
                    :errors="errors"
                    @blurEvent="updateData(null, 'sNIPUpright')"
                  />
                </div>
                <div class="col-4">
                  <dropdown
                    v-if="handleLogicStatement('sNIPUprightUnit', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.sNIPUprightUnit"
                    label="SNIP (upright) (cm H2O) Unit"
                    name="sNIPUprightUnit"
                    :options="options.unitList"
                    :logic="logic"
                    :content="content"
                    :errors="errors"
                    @changeEvent="updateData(null, 'sNIPUprightUnit')"
                  />
                </div>
                <div class="col-4">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="sNIPUprightNotPerformed"
                    switch
                    v-model="
                      content.pulmonaryFunctionTest.sNIPUprightNotPerformed
                    "
                    :disabled="handleLogicStatement('sNIPUprightNotPerformed', 'disable')"
                    @input="eventOnInput('sNIPUprightNotPerformed', ['sNIPUprightUnit', 'sNIPUpright'])"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.sNIPUprightNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- SNIP (supine) (cm H2O) -->
              <div class="row">
                <div class="col-4">
                  <decimal-input
                    v-if="handleLogicStatement('sNIPSupine', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.sNIPSupine"
                    label="SNIP (supine) (cm H2O)"
                    name="sNIPSupine"
                    placeholder="SNIP (supine) (cm H2O)"
                    :logic="logic"
                    :content="content"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-4">
                  <dropdown
                    v-if="
                      handleLogicStatement('sNIPSupineUnit', 'visibility')
                    "
                    v-model="content.pulmonaryFunctionTest.sNIPSupineUnit"
                    label="SNIP (supine) (cm H2O) Unit"
                    name="sNIPSupineUnit"
                    :options="options.unitList"
                    :logic="logic"
                    :content="content"
                    :errors="errors"
                    @changeEvent="updateData"
                  />
                </div>
                <div class="col-4">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="sNIPSupineNotPerformed"
                    switch
                    v-model="content.pulmonaryFunctionTest.sNIPSupineNotPerformed"
                    :disabled="handleLogicStatement('sNIPSupineNotPerformed', 'disable')"
                    @input="eventOnInput('sNIPSupineNotPerformed', ['sNIPSupineUnit', 'sNIPSupine'])"
                  >
                      <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.sNIPSupineNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- VC (upright) (L) -->
              <div class="row">
                <div class="col-6">
                  <decimal-input
                    v-if="handleLogicStatement('vCUprightL', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.vCUprightL"
                    label="VC (upright) (L)"
                    name="vCUprightL"
                    placeholder="VC (upright) (L)"
                    :content="content"
                    :logic="logic"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-6">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    name="check-button"
                    id="vCUprightLNotPerformed"
                    switch
                    v-model="content.pulmonaryFunctionTest.vCUprightLNotPerformed"
                    :disabled="handleLogicStatement('vCUprightLNotPerformed', 'disable')"
                    @input="eventOnInput('vCUprightLNotPerformed', ['vCUprightL'])"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.vCUprightLNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>

              <!-- VC (supine) (L) -->
              <div class="row">
                <div class="col-6">
                  <decimal-input
                    v-if="handleLogicStatement('vCSupineL', 'visibility')"
                    v-model="content.pulmonaryFunctionTest.vCSupineL"
                    label="VC (supine) (L)"
                    name="vCSupineL"
                    placeholder="VC (supine) (L)"                  
                    :content="content"
                    :logic="logic"
                    :errors="errors"
                    @blurEvent="updateData"
                  />
                </div>
                <div class="col-6">
                  <label> Test Performed ? </label>
                  <b-form-checkbox
                    class="mt-1"
                    id="vCSupineLNotPerformed"
                    name="check-button"
                    switch
                    v-model="content.pulmonaryFunctionTest.vCSupineLNotPerformed"
                    :disabled="handleLogicStatement('vCSupineLNotPerformed', 'disable')"
                    @input="eventOnInput('vCSupineLNotPerformed', ['vCSupineL'])"
                  >
                    <label class="checkbox-label">
                      {{
                        checkboxTextLabel(
                          content.pulmonaryFunctionTest.vCSupineLNotPerformed
                        )
                      }}
                    </label>
                  </b-form-checkbox>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <h6>Add Comment</h6>

          <div
            class="mt-3"
            v-for="(item, idx) in content.pulmonaryFunctionTest
              .comments"
            :key="idx"
          >
            <div class="buttons">
              <span class="h6">
                Comment #{{ idx + 1 }}
                <a
                  v-if="idx > 0"
                  href="javascript:void(0)"
                  class="ml-2 text-danger"
                  @click="deleteComment(idx)"
                >
                  <b-icon icon="trash" aria-hidden="true"></b-icon> </a
              ></span>
            </div>
            <text-area
              :index="idx"
              v-model="content.pulmonaryFunctionTest.comments[idx]"
              class="mt-2"
              name="comments"
              placeholder="Enter Comment"
              :content="content"
              :logic="logic"
              :errors="errors"
              @blurEvent="updateData"
            />
          </div>

          <!-- button to add another comment -->
          <div class="buttons mt-2">
            <button type="button" class="btn btn-sm btn-link" @click="addComment">
              <b-icon icon="plus" aria-hidden="true"></b-icon>
              Add another comment
            </button>
          </div>

          <hr />

          <div
            v-if="canManagePatient"
            class="text-center buttons mt-3"
          >
            <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
              Save & Continue
            </button>
          </div>
        </form>
      </fieldset>
    </b-overlay>
  </div>
</template>

<script>
import PulmonaryFunctionTestMixin from './PulmonaryFunctionTest.mixin';
import { $getInfoText } from '@/helper/globalFunction';

export default {
  name: 'PulmonaryFunctionTestForm',
  props: {
    canManagePatient: {
      type: Boolean,
      default: false,
    }
  },
  mixins: [
    PulmonaryFunctionTestMixin
  ],
  data: () => {
    return {
      infoText: "",
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    async addComment() {
      await this.content.pulmonaryFunctionTest.comments.push("");
      this.updateData();
    },

    async deleteComment(idx) {
      await this.content.pulmonaryFunctionTest.comments.splice(
        idx,
        1
      );
      this.updateData();
    },

    // return checkbox text
    checkboxTextLabel(value) {
      return value ? "No" : "Yes";
    },

    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "pulmonaryFunctionTest",
        "GET",
        response.data.nav
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "pulmonaryFunctionTestInfo",
        this.options.info
      );
      
      this.dateFormatter();
      this.getDataSuccess = true;
    },

    // This will update field value
    customUpdateField(params) {
      const { field, value, index } = params
      
      if (index !== null && index > -1) {
        if (field === 'comments') {
          this.content.pulmonaryFunctionTest.comments[index] = value;
        }
      } else {
        this.content.pulmonaryFunctionTest[field] = value;
      }
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (this.errors.length > 1) {
          this.errors.forEach(el => {
            this.removeError(el.field);
          })
        } if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
    eventOnInput(currentField, affectedField) {
      this.updateRelatedField(currentField, affectedField);
      this.updateData(currentField);
    }
  },
};
</script>

<style lang="scss">
.checkbox-label {
  margin-top: 2px;
}
</style>
