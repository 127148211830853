<template>
  <div id="patient-creation">
    <custom-breadcrumb class="mt-2" :items="breadcrumbs" />
    
    <div class="row">
      <div class="col"></div>
      <div class="col-lg-8 col-md-8 col-sm-12 col-12"></div>
      <div class="col"></div>
    </div>

    <div class="custom-container">
      <h6 class="text-muted"> Patient ID : {{ getPatientRef() }} </h6>
      <h6 class="text-muted"> Assessment : {{ sequence }}</h6>
      <hr />

      <b-tabs>
        <b-tab
          v-for="(item, idx) in tabs"
          :key="idx"
          :id="item.page"
          :title="item.label"
          :active="item.page === selectedTab"
          @click="selectTab(item.page)"
        />
        <br />
        <component 
          :userData="userData"
          :is="selectedTab"
          :can-manage-patient="canManagePatient"
          @get-sequence="getSequence"
        />
      </b-tabs>

      <div 
          v-if="!canManagePatient" 
          class="text-center"
        >
          <button
            class="btn btn-sm btn-pompe-primary ml-2"
            type="button"
            @click.prevent="$router.push({
              name: 'patientOverview',
              params: {
                tab: 'assessmentOverview',
                id: $route.params.id
              }
            })"
          >
            Back to assessment diagnosis
          </button>
        </div>
    </div>
  </div>
</template>

<script>
// Import Form Components
import vitalSigns from "./Sections/VitalSigns/Index";
import sixMinuteWalkTest from "./Sections/SixMinuteWalkTest/Index";
import pulmonaryFunctionTest from "./Sections/PulmonaryFunctionTest/Index";
import motorFunctionalActivities from "./Sections/MotorFunctionalActivities/Index";
import mRCScale from "./Sections/MRCScale/Index";
import respiratoryAssessment from "./Sections/RespiratoryAssessment/Index";
import modifiedRankingScale from "./Sections/ModifiedRankingScale/Index";
import rotterdam9ItemHandicapScale from "./Sections/RotterdamScale/Index";
import muscoskeletalMotorAssessment from "./Sections/MuscoskeletalMotor/Index";
import waltonGardnerMewinScale from "./Sections/WaltonGardnerMewinScale/Index";
import fatigueSeverityScale from "./Sections/FatigueSeverityScale/Index";

// Vuex
import {
  mapState,
  mapActions,
  mapGetters,
} from "vuex";

export default {
  name: 'AssessmentForm',
  components: {
    vitalSigns,
    sixMinuteWalkTest,
    pulmonaryFunctionTest,
    mRCScale,
    motorFunctionalActivities,
    respiratoryAssessment,
    modifiedRankingScale,
    rotterdam9ItemHandicapScale,
    muscoskeletalMotorAssessment,
    waltonGardnerMewinScale,
    fatigueSeverityScale,
  },
  data: () => ({
    sequence: 0,
    breadcrumbs: [
      { key: 'home', text: 'Home' },
      { key: 'assessment-data', text: 'Assessment Data' }
    ],
    canManagePatient: true
  }),
  computed: {
    ...mapState({
      selectedTab: (state) => state.assessment.selectedTab,
      patientRef: (state) => state.assessment.patientRef,
    }),
    ...mapGetters({
      tabs: 'tabs/getTabs',
      userData: 'general/getUserData',
      patientData: 'creation/getPatientData'
    }),
  },
  watch: {
    userData: {
      handler(val) {
        this.checkAccess(val);
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    this.setAssessmentTab(this.$route.params.tab);
    this.constructBreadcrumb();
  },
  methods: {
    ...mapActions({
      reqPatientRef: 'assessment/reqPatientRef',
      assignTabs: 'tabs/assignTabs',
      reconstructTabs: 'tabs/reconstructTabs',
      setAssessmentTab: "assessment/setSelectedTab",
    }),
    getPatientRef() {
      if (this.patientRef) {
        return this.patientRef;
      } else {
        this.reqPatientRef(this.$route.params.id);
        return this.patientRef;
      }
    },
    constructBreadcrumb() {
      const { state } = this.$route.params;
      if (state && (state === 'edit' || state.includes('overview'))) {
        this.breadcrumbs = [
          { 
            key: 'home',
            text: 'Home',
            action: () => window.location.href = '/web',
          },
          { 
            key: 'assessment-diagnosis',
            text: 'Assessment Diagnosis',
            action: () => this.goToAssessmentDiagnosis(this.$route.params.id)
          },
          { 
            key: 'assessment-data-action',
            text: state.includes('overview') ? 'New Assessment' : 'Assessment Data Edit',
            active: true
          }
        ];
      } else {
        this.breadcrumbs = [
          { 
            key: 'home',
            text: 'Home',
            action: () => window.location.href = '/web',
          },
          { 
            key: 'assessment-diagnosis',
            text: 'Assessment',
          },
        ];
      }
    },
    selectTab(tab) {
      this.setAssessmentTab(tab);
      this.$router.push({
        name: "assessmentData",
        params: { tab, id: this.$route.params.id },
      });
    },
    getSequence(seq) {
      this.sequence = seq;
    },
    // if edit only
    goToAssessmentDiagnosis(id) {
      this.$router.push({
        name: 'patientOverview',
        params: {
          tab: 'assessmentOverview',
          id
        }
      });
    },
    async checkAccess({ role, id }) {
      await this.setPatientData(this.$route.params.id);
      const { physicianId } = this.patientData;

      if (this.$route.path.includes('edit')) {
        this.canManagePatient = (
          (role === "PHYSICIAN" && id === physicianId)
          || role === 'ADMIN' || role === 'MANAGER'
        );
      }
    },
  },
};
</script>

<style lang="scss">
.custom-container {
  padding: 1em 0;
}

.card-content {
  width: 100%;
}

.nav-link {
  text-align: left;
  color: #000;
  margin-right: 5px;
  font-weight: 500;
  font-size: 13px;
}
</style>
