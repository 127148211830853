<template>
  <div id="respiratory-assessment">
    <h5>Respiratory Assessment</h5>

    <hr />

    <div class="text-center mt-4 mb-3" v-if="getDataLoading">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
      <br />
      <label> Please Wait </label>
    </div>

    <notification
      v-if="notification.isActive"
      :type="notification.type"
      :message="notification.message"
    />

    <notification
      type="info"
      :message="infoText"
      class="mb-4"
      v-if="
        getDataSuccess &&
        handleLogicStatement('respiratoryAssessmentInfo', 'visibility')
      "
    />

    <b-overlay :show="saveDataLoading">
      <fieldset :disabled="!canManagePatient">
        <form @submit.prevent="requestSaveForm" v-if="getDataSuccess">
          <!-- Date of Assessment -->
          <datepicker
            v-if="
              handleLogicStatement('dateOfRespiratoryAssessment', 'visibility')
            "
            v-model="content.respiratoryAssessment.dateOfRespiratoryAssessment"
            label="Date of Assessment"
            name="dateOfRespiratoryAssessment"
            placeholder="Enter Date of Assessment"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement('dateOfRespiratoryAssessment', 'disable')"
            @changeEvent="updateData"
          />

          <!-- Is the patient currently receiving respiratory support -->
          <dropdown
            v-if="handleLogicStatement('receivingRespiratorySupport', 'visibility')"
            v-model="content.respiratoryAssessment.receivingRespiratorySupport"
            label="Is the patient currently receiving respiratory support"
            name="receivingRespiratorySupport"
            :options="options.defaultOpt"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- Status since the last assessment -->
          <dropdown
            v-if="handleLogicStatement('statusSinceLastAssessment', 'visibility')"
            label="Status since last assessment"
            name="statusSinceLastAssessment"
            :model.sync="content.respiratoryAssessment.statusSinceLastAssessment"
            :options="options.lastAssessmentList"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- Date of First Use -->
          <datepicker
            v-if="handleLogicStatement('dateOfFirstUse', 'visibility')"
            v-model="content.respiratoryAssessment.dateOfFirstUse"
            label="Date of First Use"
            name="dateOfFirstUse"
            placeholder="Enter Date of First Use"
            :logic="logic"
            :errors="errors"
            :isDisable="handleLogicStatement('dateOfFirstUse', 'disable')"
            @changeEvent="updateData"
          />

          <!-- Current type of support -->
          <b-form-group>
            <label> Current type of support </label>  
            <br />
            <b-form-checkbox
              inline
              v-for="item in options.typeOfSupportList"
              :key="item.value"
              :id="item.value"
              class="mt-1"
              name="check-button"
              v-model="content.respiratoryAssessment[item.value]"
              :disabled="handleLogicStatement(item.value, 'disable')"
              @input="prepareArrayOfString(item.value, 'currentTypeOfSupport')"
            >
              <label :for="item.value"> 
                <b-icon
                  v-if="item.value === 'invasiveVentilation'"
                  icon="exclamation-circle-fill"
                  v-b-tooltip.top="`if 'invasive ventilation' is selected, the physician needs to check the exclusion criteria`"
                ></b-icon>
                {{ item.text }} 
              </label>
            </b-form-checkbox>
          </b-form-group>

          <!-- Respiratory Type -->
          <dropdown
            v-if="handleLogicStatement('respiratoryAssessmentType', 'visibility')"
            v-model="content.respiratoryAssessment.respiratoryAssessmentType"
            label="Respiratory Type"
            name="respiratoryAssessmentType"
            :options="options.respiratoryTypeList"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- Other Respiratory Type -->
          <text-field
            v-if="
              handleLogicStatement('respiratoryAssessmentTypeOther', 'visibility')
            "
            v-model="content.respiratoryAssessment.respiratoryAssessmentTypeOther"
            label="Other Respiratory Type"
            name="respiratoryAssessmentTypeOther"
            placeholder="Other Respiratory Type"
            :logic="logic"
            :errors="errors"
            @blurEvent="updateData"
          />

          <!-- Duration -->
          <dropdown
            v-if="handleLogicStatement('respiratoryAssessmentDuration', 'visibility')"
            v-model="content.respiratoryAssessment.respiratoryAssessmentDuration"
            label="Duration"
            name="respiratoryAssessmentDuration"
            :options="options.durationList"
            :logic="logic"
            :errors="errors"
            @changeEvent="updateData"
          />

          <!-- Average of hours -->
          <decimal-input
            v-if="
              handleLogicStatement(
                'respiratoryAssessmentAverageHours',
                'visibility'
              )
            "
            v-model="content.respiratoryAssessment.respiratoryAssessmentAverageHours"
            label="Average of hours"
            name="respiratoryAssessmentAverageHours"
            placeholder="Enter Average of hours"
            :logic="logic"
            :errors="errors"
            @blurEvent="updateData"
          />

          <hr />

          <div
            v-if="canManagePatient"
            class="text-center buttons mt-3"
          >
            <button class="btn btn-sm btn-pompe-primary ml-2" type="submit">
              Save & Continue
            </button>
          </div>
        </form>
      </fieldset>
    </b-overlay>
  </div>
</template>

<script>
import RespiratoryAssessmentMixin from './RespiratoryAssessment.mixin';
import { $getInfoText, $constructArray } from '@/helper/globalFunction';

export default {
  name: 'RespiratoryAssessmentForm',
  props: {
    canManagePatient: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [
    RespiratoryAssessmentMixin
  ],
  data: () => {
    return {
      infoText: "",
      getDataLoading: false,
      getDataSuccess: false,
      updateDataLoading: false,
      saveDataLoading: false,
      content: null,
      logic: null,
      errors: null,
      options: null,
      notification: {
        type: "",
        message: "",
        isActive: false,
      },
      spdConfig: {
        parentLabel: 'Date of First Use',
        dayLabel: 'Date',
        dayField: 'date',
        dayModel: null,
        monthLabel: 'Month',
        monthField: 'month',
        monthModel: null,
        yearLabel: 'Year',
        yearField: 'year',
        yearModel: null,
      },
    };
  },
  mounted() {
    this.getFormData();
  },
  methods: {
    $getInfoText,
    $constructArray,
    handleGetDataSuccess(response) {
      this.adjustTab(response.data.nav);
      this.checkFormStatus(
        null,
        "respiratoryAssessment",
        "GET",
        response.data.nav
      );

      this.content = response.data.content;
      this.logic = response.data.logic;
      this.options = response.data.options;

      this.infoText = this.$getInfoText(
        "respiratoryAssessmentInfo",
        this.options.info
      );

      this.dateFormatter();
      this.getDataSuccess = true;
    },

    async prepareArrayOfString(value, nameOfArray) {
      await this.$constructArray(value, this.content.respiratoryAssessment[nameOfArray])
      this.updateData(nameOfArray);
    },

    // Update form data
    updateData(fieldName = null) {
      this.updateDataLoading = true;

      // Remove highlighted error if any
      if (this.errors) {
        if (fieldName) this.removeError(fieldName);
      }

      this.dateFormatter();
      this.requestUpdateForm();
    },
  },
};
</script>

<style></style>
